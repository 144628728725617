import { marked } from 'marked'
import React, { useEffect, useState } from 'react'
import { ARWEAVE_NODE } from '../constants'
import getChainData from '../helpers/getChainData'
import truncateAddress from '../helpers/truncateAddress'
import store from '../stores/store'
import Card from './Card'
import CardDivider from './CardDivider'
import CardPadding from './CardPadding'
import Footer from './Footer'
import HaloDescription from './HaloDescription'
import Width from './Width'

function HaloContentMedia() {
  const s = store()
  const [loading, setLoading] = useState(true)
  const [mediaUrl, setMediaUrl] = useState('')

  const preloadMedia = async () => {
    const url = await preloadAsset(`${ARWEAVE_NODE}/${s?.device?.node_id}`)

    setMediaUrl(url)
    setLoading(false)
  }

  async function preloadAsset(src) {
    const res = await fetch(src)
    const blob = await res.blob()
    return URL.createObjectURL(blob)
  }

  useEffect(() => {
    if (s.device && s.device.node_id) {
      preloadMedia()
    }

    return () => {}
  }, [])

  if (!s.device) return <></>

  const isVideo = s.device && s.device.content_type.indexOf('video') > -1

  if (loading) {
    return (
      <div className="halo-content-media-loading">
        <img src="/images/spinning-chip.gif" alt="Halo chip" />
        <p>Loading...</p>
      </div>
    )
  } else if (isVideo) {
    return <video src={mediaUrl} className="halo-content-media" autoPlay loop playsInline muted></video>
  } else {
    return <img className="halo-content-media" src={mediaUrl} />
  }
}

export default function HaloContent() {
  const s = store()
  if (!s.device) return <></>

  const minter = s.deviceCreator || truncateAddress(s.device.device_minter)
  const explorer = getChainData(Number(s.device.chain_id)).explorer
  const meta = JSON.parse(s.device.device_token_metadata)
  const chipId = store((s) => s.deviceKeys.chipId)

  return (
    <>
      <Card noRound className="halo-content">
        <HaloContentMedia />
        <Width>
          <CardPadding>
            <h1 className="halo-content-title">{meta.name}</h1>

            <p className="halo-content-minter">
              {s.deviceCreatorAvatar && (
                <img className="halo-content-minter-avatar" src={s.deviceCreatorAvatar} alt="avatar" />
              )}
              By{' '}
              <a target="_blank" href={`${explorer}/${s.device.device_minter}`}>
                {minter}
              </a>
            </p>

            <HaloDescription description={meta.description} />
          </CardPadding>
        </Width>

        {chipId && (
          <>
            <CardDivider />

            <Width>
              <CardPadding>
                <h3 className="halo-content-subheading">Chip ID</h3>
                <div className="halo-content-chip-id">{chipId}</div>
              </CardPadding>
            </Width>
          </>
        )}

        {/* <CardDivider />

      <CardPadding>
        <h3 className="halo-content-subheading">NFTS to claim</h3>
        <ClaimableNFT title="$CTZN" />
        <ClaimableNFT title="$LAND" claimed />
      </CardPadding> */}
        <CardDivider />

        <Footer />
      </Card>
    </>
  )
}
