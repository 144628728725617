import React from 'react'

export default function CircleCheck() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" fill="none" viewBox="0 0 32 32">
      <path
        className="circle-check-circle"
        fill="#0C0C0C"
        d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Z"
      />
      <path
        stroke="#FCFCFC"
        className="circle-check-check"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="m9 16 4.67 5L23 11"
      />
    </svg>
  )
}
