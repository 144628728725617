import { useWeb3Modal } from '@web3modal/react'
import React, { useEffect, useState } from 'react'
import { useAccount, useNetwork, useDisconnect } from 'wagmi'
import classNames from 'classnames'

import truncateAddress from '../helpers/truncateAddress'
import store from '../stores/store'
import ArrowUpRight from '../svg/ArrowUpRight'
import ChevronDown from '../svg/ChevronDown'
import Logo from '../svg/Logo'

export default function Header() {
  const s = store()
  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const [dropdownActive, setDropdownActive] = useState(false)
  const truncatedAddress = truncateAddress(address)
  const { disconnect } = useDisconnect()

  const dc = async () => {
    disconnect()
    s.walletDisconnect()
  }

  return (
    <header className="header">
      <div className="header-flex">
        <Logo />
        {!s.walletConnected ? (
          <button className="header-connect" onClick={() => open()}>
            Connect wallet
          </button>
        ) : (
          <div className={classNames('header-wallet', { 'header-wallet--active': dropdownActive })}>
            <button className="header-wallet-button" onClick={() => setDropdownActive(!dropdownActive)}>
              {truncatedAddress} <ChevronDown />
            </button>
            <div className="header-wallet-dropdown">
              <div className="header-wallet-dropdown-connection">
                <div className="header-wallet-dropdown-connection-indicator"></div>
                <div className="header-wallet-dropdown-connection-address">{truncatedAddress}</div>
                <div className="header-wallet-dropdown-connection-link">
                  <ArrowUpRight />
                </div>
              </div>

              <button onClick={dc} className="header-wallet-dropdown-disconnect">
                Disconnect
              </button>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}
