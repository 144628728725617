import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, useAccount, useNetwork, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'

import store from './stores/store'
import { WC_PROJECT } from './constants'
import Loading from './components/Loading'
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import Width from './components/Width'
import Routes from './components/Routes'

// Wallet connect configuation
const chains = [mainnet]
const projectId = WC_PROJECT

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient,
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

export default function Application() {
  const s = store()
  const { address, isConnected, isDisconnected } = useAccount()
  const { chain } = useNetwork()

  useEffect(() => {
    if (isConnected && address) {
      s.walletConnect(address, chain?.id || 1)
    }

    if (isDisconnected) {
      s.walletDisconnect()
    }

    s.walletSetReady()
  }, [address, isConnected, isDisconnected])

  useEffect(() => {
    // Wait for wallet to finish first
    if (s.walletReady) s.init()
  }, [s.walletReady])

  // If device check not done
  if (!s.ready) return <Loading absolute />

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Width>
          <Header />
        </Width>

        <BrowserRouter>
          <ScrollToTop />
          <Routes />
        </BrowserRouter>
      </WagmiConfig>
      <Web3Modal
        explorerExcludedWalletIds={['fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa']}
        projectId={projectId}
        ethereumClient={ethereumClient}
      />
    </>
  )
}

const el = document.querySelector('#application')

if (el) {
  const root = ReactDOM.createRoot(el)
  root.render(<Application />)
}
