import React from 'react'

interface IProps {
  type: 'text' | 'textarea'
  placeholder?: string
  name: string
  disabled?: boolean
  onChange(key: string, value: string): void
  value: string
}

export default function Field({ type, placeholder, name, onChange, disabled, value }: IProps) {
  const handleChange = (e) => {
    const { value } = e.target
    onChange(name, value)
  }

  if (type === 'textarea') {
    return (
      <div className="field">
        <textarea onChange={handleChange} value={value} name={name} disabled={disabled} placeholder={placeholder} />
      </div>
    )
  } else {
    return (
      <div className="field">
        <input onChange={handleChange} value={value} name={name} disabled={disabled} placeholder={placeholder} />
      </div>
    )
  }
}
