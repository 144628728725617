import React from 'react'
import classNames from 'classnames'

interface IProps {
  className?: string
  children: React.ReactNode
  fullWidth?: boolean
  disabled?: boolean
  onClick?(): void
}

export default function Button({ className, children, fullWidth, disabled, onClick }: IProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames('button', { 'button--full-width': fullWidth }, className)}
    >
      {children}
    </button>
  )
}
