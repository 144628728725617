import React, { useEffect } from 'react'
import Card from '../components/Card'
import CardPadding from '../components/CardPadding'
import Field from '../components/Field'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ImageField from '../components/ImageField'
import RegisterBack from '../components/RegisterBack'
import Width from '../components/Width'
import Button from '../components/Button'
import registerStore from '../stores/registerStore'
import { useNavigate } from 'react-router-dom'
import store from '../stores/store'
import CardDivider from '../components/CardDivider'

export default function Register() {
  const s = registerStore()
  const device = store((s) => s.device)
  const connected = store((s) => s.walletConnected)
  const validForm = s.formValid.image
  const n = useNavigate()

  const handleFieldChange = (name, value) => {
    s.setFormField(name, value)
    s.setFormValidField(name, value.length > 0)
  }

  useEffect(() => {
    if (!device || !connected) {
      n('/')
    } else if (s.sigSplit && s.block) {
      n('/confirm')
    }
  }, [device, connected, s])

  return (
    <div className="register">
      <Card noRound>
        <Width>
          <CardPadding>
            <RegisterBack to="/">Register</RegisterBack>
            <ImageField />
            <Field
              onChange={handleFieldChange}
              type="text"
              name="name"
              placeholder="Enter name..."
              value={s.form.name}
            />
            <Field
              onChange={handleFieldChange}
              type="textarea"
              name="description"
              placeholder="Enter description..."
              value={s.form.description}
            />
            <Button fullWidth disabled={!validForm} onClick={s.scanHalo}>
              Tap Chip to Mint
            </Button>
          </CardPadding>
        </Width>
        <CardDivider />
        <Footer />
      </Card>
    </div>
  )
}
