import React from 'react'

export default function NoImage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" fill="none" viewBox="0 0 33 32">
      <path
        fill="#0c0c0c"
        fillRule="evenodd"
        d="M31.5 22.5a1 1 0 0 1 1 1c0 4.692-3.808 8.5-8.5 8.5a1 1 0 1 1 0-2c3.588 0 6.5-2.912 6.5-6.5a1 1 0 0 1 1-1ZM23 1a1 1 0 0 1 1-1c4.692 0 8.5 3.808 8.5 8.5a1 1 0 1 1-2 0C30.5 4.912 27.588 2 24 2a1 1 0 0 1-1-1ZM9 2a6.503 6.503 0 0 0-6.5 6.5 1 1 0 1 1-2 0C.5 3.808 4.308 0 9 0a1 1 0 1 1 0 2ZM1.5 22.5a1 1 0 0 1 1 1C2.5 27.088 5.412 30 9 30a1 1 0 1 1 0 2 8.503 8.503 0 0 1-8.5-8.5 1 1 0 0 1 1-1Z"
        clipRule="evenodd"
      />
      <path fill="#0c0c0c" d="M20.5 8h-8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4Z" />
      <path fill="#fff" d="M15.5 19a1 1 0 1 0 2 0v-6a1 1 0 1 0-2 0v6Z" />
      <path fill="#fff" d="M13.5 15a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Z" />
    </svg>
  )
}
