import React from 'react'

export default function X() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" fill="none" viewBox="0 0 12 13">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="m1 11.29 10-10M1 1.29l10 10"
      />
    </svg>
  )
}
