import classNames from 'classnames'
import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import Card from '../components/Card'
import CardDivider from '../components/CardDivider'
import CardPadding from '../components/CardPadding'
import Footer from '../components/Footer'
import Header from '../components/Header'
import RegisterBack from '../components/RegisterBack'
import Width from '../components/Width'
import formatDescription from '../helpers/formatDescription'
import registerStore from '../stores/registerStore'
import store from '../stores/store'
import CircleCheck from '../svg/CircleCheck'

export default function Confirm() {
  const s = registerStore()
  const device = store((s) => s.device)
  const connected = store((s) => s.walletConnect)
  const n = useNavigate()

  const handleBack = () => {
    s.clearSigs()
  }

  useEffect(() => {
    if (!device || !connected || !s.sigSplit || !s.block) {
      n('/register')
    }

    if (s.signed) {
      n('/')
    }
  }, [device, connected, s])

  if (s.loading) {
    return (
      <>
        <div className="confirm-signing">
          <div className="confirm-signing-body">
            <img src="/images/spinning-chip.gif" alt="Halo chip" />
            <p>Loading...</p>
          </div>
          <div
            className={classNames('confirm-signing-complete', {
              'confirm-signing-complete--active': s.complete,
            })}
          >
            <CircleCheck />
            Signing complete
          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      <Card noRound className={classNames('confirm')}>
        <Width>
          <CardPadding>
            <RegisterBack onClick={handleBack}>Confirm Minting</RegisterBack>
            <img className="confirm-image" src={s.base64Image ? s.base64Image : s.form.imageUrl} />
            <h2 className="confirm-name">{s.form.name || 'Untitled'}</h2>
            <div
              className="confirm-description"
              dangerouslySetInnerHTML={{ __html: formatDescription(s.form.description) || 'No description' }}
            />
          </CardPadding>
        </Width>
        <CardDivider />
        <Width>
          <CardPadding>
            <Button onClick={s.signHalo} fullWidth>
              Sign to Finalize
            </Button>
          </CardPadding>
        </Width>
        <Footer />
      </Card>
    </div>
  )
}
