import React from 'react'
import classNames from 'classnames'

interface IProps {
  className?: string
  children: React.ReactNode
  noRound?: boolean
}

export default function Card({ className, children, noRound }: IProps) {
  return <div className={classNames('card', { 'card--no-rounding': noRound }, className)}>{children}</div>
}
