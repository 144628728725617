import React from 'react'

export default function ArrowUpRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 10 10">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m6.121 3.879-4.95 4.95M6.828 8.828V3.172H1.171"
      />
    </svg>
  )
}
