import React, { useState } from 'react'
import registerStore from '../stores/registerStore'
import NoImage from '../svg/NoImage'
import CircleCheck from '../svg/CircleCheck'
import classNames from 'classnames'
import validateHttpsUrl from '../helpers/validateUrl'
import X from '../svg/X'

export default function ImageField() {
  const s = registerStore()

  const handleFileChange = (e: any) => {
    const file = e.target.files[0]

    if (file.size < 30000001) {
      s.changeFileField(file)
      s.setFormValidField('image', true)
    } else {
      alert('Media must be 5mb in size or less')
    }
  }

  const handleUrlCancel = () => {
    s.setImageFieldMode('upload')
    s.setFormField('imageUrl', '') // reset form value
    s.setFormValidField('image', false) // reset validation
  }

  const handleFileCancel = () => {
    s.setFormField('image', null)
    s.setFormValidField('image', false)
  }

  const handleUrlChange = (e) => {
    const { value } = e.target
    s.setFormField('imageUrl', value)
    s.setFormValidField('image', validateHttpsUrl(value))
  }

  return (
    <div className="image-field">
      {s.form.image != null && (
        <div className="image-field-preview">
          <img src={s.base64Image} />
          <button onClick={handleFileCancel} className="image-field-file-cancel">
            <X />
          </button>
        </div>
      )}

      {s.form.image === null && s.imageFieldMode === 'upload' && (
        <div className="image-field-empty-upload">
          <NoImage />
          <input onChange={handleFileChange} id="image" type="file" className="input-field-empty-upload-file" />
          <label htmlFor="image" className="input-field-empty-upload-file-label">
            Upload Image
          </label>
          {/* <button className="input-field-empty-upload-url" onClick={() => s.setImageFieldMode('url')}>
            Insert URL
          </button> */}
        </div>
      )}

      {s.form.image === null && s.imageFieldMode === 'url' && (
        <div className="image-field-empty-url">
          <textarea onChange={handleUrlChange} className="image-field-empty-url-textarea" value={s.form.imageUrl} />
          <button className="image-field-empty-url-cancel" onClick={handleUrlCancel}>
            Cancel
          </button>

          <div
            className={classNames('image-field-empty-url-valid', {
              'image-field-empty-url-valid--valid': s.formValid.image,
            })}
          >
            <CircleCheck />
          </div>
        </div>
      )}
    </div>
  )
}
