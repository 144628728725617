import React from 'react'

export default function CircleArrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path fill="#0C0C0C" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
      <path stroke="#FCFCFC" strokeLinecap="round" strokeLinejoin="round" d="M5 8h6M8 5 5 8l3 3" />
    </svg>
  )
}
