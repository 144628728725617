import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Routes as R, Route, useLocation } from 'react-router-dom'
import Confirm from '../pages/Confirm'
import Home from '../pages/Home'
import Register from '../pages/Register'

export default function Routes() {
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location)
  const [transitionStage, setTransistionStage] = useState('fadeIn')
  const [reverse, setReverse] = useState(false)

  const getRouteValue = (location) => {
    if (location.pathname === '/confirm') {
      return 3
    } else if (location.pathname === '/register') {
      return 2
    } else {
      return 1
    }
  }

  useEffect(() => {
    if (location !== displayLocation) {
      setReverse(getRouteValue(location) < getRouteValue(displayLocation))
      setTransistionStage('fadeOut')
    }
  }, [location])

  return (
    <div className={classNames({ 'page-transition-reversed': reverse })}>
      <div
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === 'fadeOut') {
            setTransistionStage('fadeIn')
            setDisplayLocation(location)
          }
        }}
      >
        <R location={displayLocation}>
          <Route index element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm" element={<Confirm />} />
        </R>
      </div>
    </div>
  )
}
