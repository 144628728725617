import React from 'react'
import NoHalo from './NoHalo'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Width from '../components/Width'
import store from '../stores/store'
import HaloContent from '../components/HaloContent'
import { Link } from 'react-router-dom'

export default function Home() {
  const device = store((s) => s.device)
  const deviceRegistered = store((s) => s.deviceRegistered)
  const registered = device && deviceRegistered

  return (
    <div>
      {registered ? (
        <HaloContent />
      ) : (
        <Width>
          <NoHalo />
          <Footer />
        </Width>
      )}
    </div>
  )
}
