import classNames from 'classnames'
import React, { useState } from 'react'
import formatDescription from '../helpers/formatDescription'

interface IProps {
  description: string
}

export default function HaloDescription({ description }: IProps) {
  const hasMore = description.length > 140
  const [truncated, setTruncated] = useState(description.length > 140)

  return (
    <div className="halo-description">
      <div
        className={classNames('halo-description-content', { 'halo-description-content--truncated': truncated })}
        dangerouslySetInnerHTML={{ __html: formatDescription(description) }}
      />
      {hasMore && (
        <>
          {truncated ? (
            <button onClick={() => setTruncated(false)} className="halo-description-read">
              Read more
            </button>
          ) : (
            <button onClick={() => setTruncated(true)} className="halo-description-read">
              Read less
            </button>
          )}
        </>
      )}
    </div>
  )
}
