import React from 'react'
import { Link } from 'react-router-dom'
import CircleArrowLeft from '../svg/CircleArrowLeft'

interface IProps {
  onClick?(): void
  to?: string
  children?: React.ReactNode
}

export default function RegisterBack({ to, onClick, children }: IProps) {
  if (!to && !onClick) return <></>

  return (
    <div className="register-back">
      {to ? (
        <Link to={to} className="register-back-inner">
          <CircleArrowLeft />
          {children}
        </Link>
      ) : (
        <div onClick={onClick} className="register-back-inner">
          <CircleArrowLeft />
          {children}
        </div>
      )}
    </div>
  )
}
