import React from 'react'
import classNames from 'classnames'

interface IProps {
  className?: string
  children: React.ReactNode
}

export default function CardPadding({ className, children }: IProps) {
  return <div className={classNames('card-padding', className)}>{children}</div>
}
