import React from 'react'

export default function Ers() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" fill="none" viewBox="0 0 26 22">
      <path
        stroke="currentColor"
        d="M16.842.5c1.374 0 2.643.733 3.33 1.922l3.842 6.656a3.845 3.845 0 0 1 0 3.845l-3.842 6.655a3.845 3.845 0 0 1-3.33 1.922H9.158a3.845 3.845 0 0 1-3.33-1.922l-3.842-6.655a3.845 3.845 0 0 1 0-3.845l3.842-6.656A3.845 3.845 0 0 1 9.158.5h7.684Z"
      />
      <path
        fill="currentColor"
        d="M6.476 7.975h3.844v.94H7.565v1.403h2.21v.94h-2.21v1.543h2.846v.949H6.476V7.975ZM15.23 13.75h-1.081l-.14-1.42a.832.832 0 0 0-.248-.536.7.7 0 0 0-.528-.214h-1.246v2.17H10.89V7.975h2.368c.396 0 .737.077 1.023.23.291.15.511.358.66.628a1.8 1.8 0 0 1 .23.924c0 .56-.258.976-.775 1.245.198.127.358.295.479.504.12.203.192.437.214.7l.14 1.544Zm-3.243-3.11h1.221c.303 0 .528-.083.677-.248a.912.912 0 0 0 .23-.635.854.854 0 0 0-.23-.62c-.149-.164-.374-.247-.677-.247h-1.22v1.75ZM17.693 13.816c-.434 0-.816-.083-1.146-.248a1.948 1.948 0 0 1-.776-.701 2.14 2.14 0 0 1-.305-1.056h1.048c.027.324.145.583.354.775.21.187.484.28.825.28.32 0 .572-.062.76-.189a.648.648 0 0 0 .288-.57c0-.164-.052-.299-.157-.403a1.014 1.014 0 0 0-.404-.24c-.16-.06-.38-.126-.66-.198-.396-.099-.72-.2-.973-.305a1.683 1.683 0 0 1-.644-.511c-.176-.231-.264-.542-.264-.933 0-.313.083-.59.248-.833.17-.247.407-.437.71-.57a2.466 2.466 0 0 1 1.03-.205c.402 0 .757.074 1.065.222.313.143.558.35.734.62.181.269.28.582.297.94h-1.04a.883.883 0 0 0-.321-.61c-.187-.155-.432-.232-.735-.232-.286 0-.511.06-.676.182a.573.573 0 0 0-.248.486c0 .17.053.308.157.413.11.104.245.187.404.247.165.055.394.116.685.182.402.093.726.192.974.297.253.099.47.264.651.495.182.23.273.544.273.94 0 .352-.091.658-.273.916-.181.259-.434.46-.759.602a2.855 2.855 0 0 1-1.122.207Z"
      />
    </svg>
  )
}
