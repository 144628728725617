import { REACT_APP_INFURA_PROJECT_ID } from '../constants'
import { SUPPORTED_CHAINS } from './chains'

interface IChainData {
  name: string
  short_name: string
  chain: string
  network: string
  chain_id: number
  network_id: number
  explorer: string
  rpc_url: string
  native_currency: {
    symbol: string
    name: string
    decimals: string
    contractAddress: string
    balance?: string
  }
}

export default function getChainData(chainId: number): IChainData {
  const chainData = SUPPORTED_CHAINS.filter((chain: any) => chain.chain_id === chainId)[0]

  if (!chainData) {
    throw new Error('ChainId missing or not supported')
  }

  const API_KEY = REACT_APP_INFURA_PROJECT_ID

  if (chainData.rpc_url.includes('infura.io') && chainData.rpc_url.includes('%API_KEY%') && API_KEY) {
    const rpcUrl = chainData.rpc_url.replace('%API_KEY%', API_KEY)

    return {
      ...chainData,
      rpc_url: rpcUrl,
    }
  }

  return chainData
}
