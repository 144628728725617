import { create } from 'zustand'
import { ethers } from 'ethers'
import axios from 'axios'

import store from './store'
import getChainData from '../helpers/getChainData'
import generateCmd from '../helpers/generateCMD'
import buf2hex from '../helpers/bufToHex'
import unpackDERSig from '../helpers/unpackDERSig'
import ipfsHash from 'ipfs-only-hash'
import { signTypedData } from '@wagmi/core'
import formatMinterSig from '../helpers/formatMinterSig'
import { BRIDGE_MINT_ENDPOINT, SIMULATE_TRANSACTIONS } from '../constants'

type imageFieldModes = 'upload' | 'url'

type TRegisterStore = {
  imageFieldMode: imageFieldModes
  base64Image?: string
  sigSplit: any
  sigMsg: any
  block: any
  loading: boolean
  message: string
  signed: boolean
  complete: boolean

  form: {
    name: string
    description: string
    imageUrl: string
    image: any
  }

  formValid: {
    name: boolean
    description: boolean
    image: boolean
  }

  setImageFieldMode(mode: imageFieldModes): void
  setFormValidField(key: string, valid: boolean)
  setFormField(key: string, value: any): void
  changeFileField(file: any): void
  scanHalo(): void
  clearSigs(): void
  signHalo(): void
}

const registerStore = create<TRegisterStore>((set) => ({
  imageFieldMode: 'upload',
  base64Image: undefined,
  sigSplit: false,
  sigMsg: false,
  block: false,
  loading: false,
  message: '',
  signed: false,
  complete: false,

  form: {
    name: '',
    description: '',
    imageUrl: '',
    image: null,
  },

  formValid: {
    name: false,
    description: false,
    image: false,
  },

  setImageFieldMode: (imageFieldMode) => {
    set({ imageFieldMode })
  },

  setFormField: (key, value) => {
    set((state) => {
      return {
        form: {
          ...state.form,
          [key]: value,
        },
      }
    })
  },

  setFormValidField: (key, valid) => {
    set((state) => {
      return {
        formValid: {
          ...state.formValid,
          [key]: valid,
        },
      }
    })
  },

  changeFileField: (file) => {
    // Set state to the file
    set((state) => ({
      form: {
        ...state.form,
        image: file,
      },
    }))

    // Generate a preview
    var FR = new FileReader()

    FR.addEventListener('load', function (e: any) {
      set({ base64Image: e.target.result })
    })

    FR.readAsDataURL(file)
  },

  scanHalo: async () => {
    const { deviceTriggerScan, walletChainId } = store.getState()
    const ethNode = getChainData(walletChainId).rpc_url
    const provider: any = new ethers.providers.JsonRpcProvider(ethNode)

    const block = await provider.getBlock()
    // Note: we may want to change this format to accomodate more data than the blockHash in the future.
    const sigMsg = block.hash
    const sigCmd = generateCmd(1, 1, sigMsg)
    const sig = await deviceTriggerScan(sigCmd)
    const sigString = buf2hex(sig)
    const sigSplit = unpackDERSig(sigString)

    set({ sigSplit, sigMsg, block })
  },

  clearSigs: () => {
    set({ sigSplit: false, sigMsg: false, block: false })
  },

  signHalo: async () => {
    // Get all the data we'll need
    const { deviceKeys, walletAddress, walletChainId, deviceRetrieve } = store.getState()
    const device_id = deviceKeys?.primaryPublicKeyHash?.substring(2)
    const { name, description, image, imageUrl } = registerStore.getState().form
    const device_token_metadata = { name, description }
    const { block, sigMsg, sigSplit, base64Image } = registerStore.getState()

    // Create ipfs hash
    const ipfsCid = await ipfsHash.of(base64Image)

    // Form data
    const types = {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
      ],
      Device: [
        { name: 'id', type: 'string' },
        { name: 'signatureR', type: 'string' },
        { name: 'signatureS', type: 'string' },
        { name: 'digest', type: 'string' },
      ],
      Media: [
        { name: 'cid', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'description', type: 'string' },
        { name: 'minter', type: 'address' },
        { name: 'device', type: 'Device' },
      ],
    }

    const domain = {
      name: 'ERS',
      version: '0.1.0',
      chainId: walletChainId,
    }

    const value = {
      cid: ipfsCid,
      name: device_token_metadata.name,
      description: device_token_metadata.description,
      minter: walletAddress,
      device: {
        id: device_id,
        signatureR: sigSplit.r,
        signatureS: sigSplit.s,
        digest: sigMsg,
      },
    }

    // @ts-ignore Sign it
    signTypedData({ domain, types, message: value, primaryType: 'Media' })
      .then((result) => {
        // Set loading state
        set({ loading: true })

        // Format for ajax request
        const data = {
          media: image,
          device_id,
          device_token_metadata: JSON.stringify(device_token_metadata),
          device_sig: JSON.stringify(sigSplit),
          device_sig_msg: sigMsg, // Note: we may want to include more data here than just block information, hence blockNumber alone is insufficient.
          blockNumber: block.number,
          minter_addr: walletAddress,
          minter_sig: JSON.stringify(formatMinterSig(result)),
          minter_chain_id: walletChainId,
        }

        function getFormData(object: any) {
          const formData = new FormData()
          Object.keys(object).forEach((key) => formData.append(key, object[key]))
          return formData
        }

        const form = getFormData(data)

        if (SIMULATE_TRANSACTIONS) {
          setTimeout(() => {
            set({ complete: true })

            setTimeout(() => {
              set({ signed: true, loading: false })
            }, 2000)
          }, 10000)
        } else {
          // Send it
          axios
            .post(BRIDGE_MINT_ENDPOINT, form, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((res) => {
              const poller = setInterval(async () => {
                // Refetch record
                await deviceRetrieve()

                // If complete should say registered
                const { deviceRegistered } = store.getState()

                // Once complete
                if (deviceRegistered) {
                  set({ complete: true })

                  setTimeout(() => {
                    clearInterval(poller)
                    set({ signed: true, loading: false })
                  }, 2000)
                }
              }, 5000)
            })
            .catch((err) => {
              set({ loading: false })
              alert('Something went wrong post.')
              console.log(err)
            })
        }
      })
      .catch((error) => {
        set({ loading: false })
        console.log(error)
        alert('Something went wrong pre.')
      })
  },
}))

export default registerStore
