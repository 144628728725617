import React from 'react'
import None from '../svg/NoHalo'

export default function AnimatedHaloChip() {
  return (
    <div className="animated-halo-chip">
      <None />
    </div>
  )
}
