import React from 'react'

export default function NoHalo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" fill="none" viewBox="0 0 61 60">
      <g clipPath="url(#a)">
        <path
          fill="#000"
          d="M30.5 60c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30C13.931 0 .5 13.431.5 30c0 16.569 13.431 30 30 30Z"
        />
        <path
          fill="#fff"
          d="M30.5 4.79c6.734 0 13.06 2.618 17.826 7.384A25.053 25.053 0 0 1 55.71 30c0 6.734-2.618 13.06-7.384 17.826A25.053 25.053 0 0 1 30.5 55.21c-6.734 0-13.06-2.618-17.826-7.384A25.053 25.053 0 0 1 5.29 30c0-6.734 2.618-13.06 7.384-17.826A25.053 25.053 0 0 1 30.5 4.79Zm0-1.247C15.888 3.543 4.043 15.388 4.043 30S15.888 56.457 30.5 56.457 56.957 44.612 56.957 30 45.112 3.543 30.5 3.543ZM35.58 30l2.116-3.175 5.096-7.643h-6.499l-4.57 6.86s-.024.03-.031.046l-.063.094a7.054 7.054 0 0 0-1.043 2.76h-.164a7.055 7.055 0 0 0-1.043-2.76l-.063-.094c-.008-.015-.023-.031-.031-.047l-4.57-6.859h-6.499l2.799 4.202L25.428 30l-2.116 3.175-5.096 7.643h6.499l4.57-6.86s.023-.03.031-.046l.063-.094a7.056 7.056 0 0 0 1.043-2.76h.164a7.054 7.054 0 0 0 1.043 2.76l.063.094c.007.015.023.031.03.047l4.571 6.859h6.499l-2.799-4.202L35.58 30Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h60v60H.5z" />
        </clipPath>
      </defs>
    </svg>
  )
}
