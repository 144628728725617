import React from 'react'
import classNames from 'classnames'

interface IProps {
  className?: string
}

export default function CardDivider({ className }: IProps) {
  return <div className={classNames('card-divider', className)} />
}
