import React from 'react'

import store from '../stores/store'
import Button from '../components/Button'
import Card from '../components/Card'
import CardDivider from '../components/CardDivider'
import CardPadding from '../components/CardPadding'
import CircleCheck from '../svg/CircleCheck'
import { useNavigate } from 'react-router-dom'
import AnimatedHaloChip from '../components/AnimatedHaloChip'

export default function NoHalo() {
  const device = store((s) => s.device)
  const deviceLink = store((s) => s.deviceLink)
  const connected = store((s) => s.walletConnected)
  const chipId = store((s) => s.deviceKeys.chipId)
  const deviceRegistered = store((s) => s.deviceRegistered)
  const navigate = useNavigate()
  const goToRegister = () => navigate('/register')

  return (
    <Card>
      <CardPadding className="home-top card-padding--side-padding">
        <AnimatedHaloChip />

        {device && deviceRegistered ? (
          <>
            <h2>Halo Detected</h2>
            <p>This device hasn't been registered. Tap link below to link device.</p>
          </>
        ) : (
          <>
            <h2>No Halo Detected</h2>
            <p>Scan HaLo by tapping the button below and holding the chip to your smartphone NFT antenna.</p>
          </>
        )}
      </CardPadding>
      {chipId && (
        <>
          <CardDivider />
          <CardPadding className="home-primary card-padding--side-padding">
            <h3>Chip ID</h3>
            <div className="home-chip-id">{chipId}</div>
          </CardPadding>
        </>
      )}
      <CardDivider />
      <CardPadding className="home-scan card-padding--side-padding">
        {device ? (
          <>
            <Button disabled={!connected} fullWidth onClick={goToRegister}>
              Link Halo
            </Button>

            {connected ? (
              <p className="home-scan-connected">
                <CircleCheck />
                Wallet Connected
              </p>
            ) : (
              <p className="home-scan-connect">Connect wallet to link halo</p>
            )}
          </>
        ) : (
          <>
            <Button onClick={deviceLink} fullWidth>
              Initiate Scan
            </Button>
          </>
        )}
      </CardPadding>
    </Card>
  )
}
