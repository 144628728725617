import React from 'react'

export default function Logo() {
  return (
    <svg width="58" viewBox="0 0 174 60" fill="none">
      <path
        d="M102.705 15.76h-19.83V58.47H68.636v-38.39c0-10.247 8.306-18.56 18.56-18.56h15.508V15.76zM59.993 1.528V58.47H45.756v-2.948A29.849 29.849 0 0129.996 60C13.433 59.993 0 46.568 0 29.997 0 13.426 13.432 0 29.997 0c5.782 0 11.18 1.644 15.759 4.478v-2.95h14.237zM45.756 30.003c0-8.704-7.055-15.759-15.76-15.759-8.704 0-15.759 7.055-15.759 15.76 0 8.704 7.055 15.759 15.76 15.759 8.704 0 15.759-7.055 15.759-15.76zm108.251 0l5.567-8.353L172.993 1.53h-17.112L143.848 19.58l-.081.121-.162.237a18.565 18.565 0 00-2.753 7.264h-.426a18.373 18.373 0 00-2.753-7.257l-.155-.244-.081-.121-12.026-18.052h-17.112l7.372 11.051 11.613 17.417-5.566 8.353-13.412 20.121h17.112L137.45 40.42l.081-.121.163-.237a18.549 18.549 0 002.752-7.264h.427a18.386 18.386 0 002.752 7.257l.156.244.081.121 12.033 18.052h17.111l-7.372-11.051-11.613-17.417h-.014z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
