import { create } from 'zustand'
import URL from 'url-parse'

import parseKeys from '../helpers/parseKeys'
import generateArweaveQuery from '../helpers/generateArweaveQuery'
import { ARWEAVE_GRAPHQL } from '../constants'
import axios from 'axios'
import { createDeviceObject } from '../helpers/createDeviceObject'
import checkDeviceRegistered from '../helpers/checkDeviceRegistered'
import { ethers } from 'ethers'
import getChainData from '../helpers/getChainData'
import bufToHex from '../helpers/bufToHex'
import fromHexString from '../helpers/fromHexString'
import makeStatic from '../helpers/makeStatic'

declare var window: any

export interface IDevice {
  node_id: string
  app_name: string
  app_version: string
  content_type: string
  device_record_type: string
  device_id: string
  device_token_metadata: string
  device_address: string
  device_manufacturer: string
  device_model: string
  device_merkel_root: string
  device_minter: string
  device_registry: string
  ifps_add: string
  chain_id: string
}

interface IDeviceKeys {
  primaryPublicKeyHash: string | null | undefined
  primaryPublicKeyRaw: string | null | undefined
  secondaryPublicKeyHash: string | null | undefined
  secondaryPublicKeyRaw: string | null | undefined
  tertiaryPublicKeyHash?: string | null | undefined
  tertiaryPublicKeyRaw?: string | null | undefined
  chipId?: string | null | undefined
}

type TStore = {
  // Global state
  ready: boolean

  // Wallet state
  walletReady: boolean
  walletConnected: boolean
  walletChainId: number
  walletAddress: string

  // Device state
  deviceKeys: IDeviceKeys
  device: IDevice | null
  deviceRegistered: boolean
  deviceCreator: string | null
  deviceCreatorAvatar: string | null

  // Global functions
  init(): void

  // Wallet functions
  walletSetReady(): void
  walletConnect(address: string, chainId: number): void
  walletDisconnect(): void

  // Device function
  deviceRetrieve(): void
  deviceTriggerScan(reqx: any): void
  deviceLink(): void
}

const store = create<TStore>((set) => ({
  // Global state
  ready: false,

  // Wallet state
  walletReady: false,
  walletConnected: false,
  walletAddress: '',
  walletChainId: 1,

  // Device state
  deviceKeys: {
    primaryPublicKeyHash: null,
    primaryPublicKeyRaw: null,
    secondaryPublicKeyHash: null,
    secondaryPublicKeyRaw: null,
    tertiaryPublicKeyHash: null,
    tertiaryPublicKeyRaw: null,
  },

  device: null,
  deviceRegistered: false,
  deviceCreator: null,
  deviceCreatorAvatar: null,

  // Global functions
  init: () => {
    const url = URL(window.location.href, true)
    const { pk1, pk2, pk3 } = url.query
    let statik = url.query.static

    if (!statik && pk1) {
      statik = makeStatic(pk1, pk2, pk3)
    }

    const deviceKeys = parseKeys(statik)

    if (deviceKeys) {
      const formatHex = (bytes) => (bytes.slice(0, 2) == '0x' ? bytes : '0x' + bytes)

      deviceKeys.chipId = ethers.utils.keccak256(
        '0x' + ethers.utils.computePublicKey(formatHex(deviceKeys.primaryPublicKeyRaw)).slice(4)
      )

      set({ deviceKeys })
      store.getState().deviceRetrieve()
    } else {
      set({ ready: true })
    }
  },

  // Wallet functions
  walletConnect: (walletAddress, walletChainId) => {
    console.log(walletChainId)
    set({ walletAddress, walletChainId, walletConnected: true })
  },

  walletDisconnect: async () => {
    set({ walletAddress: '', walletChainId: 1, walletConnected: false })
  },

  walletSetReady: () => {
    set({ walletReady: true })
  },

  // Device functions
  deviceRetrieve: async () => {
    try {
      // Get chain id
      const chain_id = store.getState().walletChainId

      // Get keys off state
      const { deviceKeys } = store.getState()
      if (!deviceKeys) return // if no keys exist

      // Generate a query for arweave
      const query = generateArweaveQuery(deviceKeys)

      // Send query to arweave
      const res = await axios.post(ARWEAVE_GRAPHQL, { query })

      // Default to first record
      let rightTransaction = res.data.data.transactions.edges[0]

      // Look for a matching registered record
      for (let i = 0; i < res.data.data.transactions.edges.length; i++) {
        const transaction = res.data.data.transactions.edges[i]

        if (!transaction?.node?.tags) continue

        let chainPass = false
        let mediaPass = false

        for (let j = 0; j < transaction.node.tags.length; j++) {
          const tag = transaction.node.tags[j]

          if (tag.name === 'Device-Record-Type' && tag.value === 'Device-Media') {
            mediaPass = true
            continue
          }

          if (tag.name === 'Device-Minter-Chain-Id' && Number(tag.value) === chain_id) {
            chainPass = true
            continue
          }
        }

        if (chainPass && mediaPass) {
          console.log('we found a match', transaction)
          rightTransaction = transaction
          break
        }
      }

      // Create device from record
      const device = createDeviceObject(rightTransaction)

      console.log('Device id:' + device.device_id)

      // Check if registered
      const deviceRegistered = checkDeviceRegistered(device, chain_id)

      if (!rightTransaction) {
        set({ ready: true, deviceRegistered: false, device })
        return
      }

      set({ ready: true, deviceRegistered, device })

      // Find ENS details in
      if (device && device.device_minter && Number(device.chain_id) === chain_id) {
        // Get address
        const ETH_NODE = getChainData(chain_id).rpc_url
        const provider: any = new ethers.providers.JsonRpcProvider(ETH_NODE)
        const deviceCreator = await provider.lookupAddress(device.device_minter)

        set({ deviceCreator })

        // Get avatar
        const resolver = deviceCreator ? await provider.getResolver(deviceCreator) : null

        if (resolver) {
          const avatarData = await resolver.getAvatar(deviceCreator)
          const url = avatarData?.linkage?.find((t) => t.type === 'url')?.content
          set({ deviceCreatorAvatar: url })
        }

        set({ ready: true, deviceRegistered, deviceCreator, device })
      } else {
      }
    } catch (err) {
      console.log(err)
      set({ ready: true })
    }
  },

  deviceTriggerScan: async (reqx: any) => {
    try {
      var req: any = {
        publicKey: {
          allowCredentials: [
            {
              id: fromHexString(reqx),
              transports: ['nfc'],
              type: 'public-key',
            },
          ],
          challenge: new Uint8Array([
            113, 241, 176, 49, 249, 113, 39, 237, 135, 170, 177, 61, 15, 14, 105, 236, 120, 140, 4, 41, 65, 225, 107,
            63, 214, 129, 133, 223, 169, 200, 21, 88,
          ]),
          rpId: window.location.host,
          timeout: 60000,
          userVerification: 'discouraged',
        },
      }

      var xdd: any = await navigator.credentials.get(req)

      return xdd?.response.signature
    } catch (err) {
      console.log('Error with scan', err)
    }
  },

  deviceLink: async () => {
    const { deviceTriggerScan, deviceRetrieve } = store.getState()
    const sig = await deviceTriggerScan('02')

    if (typeof sig !== 'undefined') {
      const sss = bufToHex(sig)
      const deviceKeys = parseKeys(sss)

      if (deviceKeys) {
        set({ deviceKeys })
        deviceRetrieve()
      }
    }
  },
}))

export default store
