import classNames from 'classnames'
import React from 'react'

interface IProps {
  absolute?: boolean
}

export default function Loading({ absolute }: IProps) {
  return (
    <div className={classNames('loading', { 'loading--absolute': absolute })}>
      <img src="/images/spinning-chip.gif" />
    </div>
  )
}
