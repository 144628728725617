import safeTag from './safeTag'

export function createDeviceObject(transaction) {
  // Create a device object from the first record
  const device = [transaction].flatMap((nodeItem: any) => {
    const node = nodeItem.node

    return {
      node_id: node.id,
      app_name: safeTag(node, 'App-Name', null),
      app_version: safeTag(node, 'App-Version', null),
      content_type: safeTag(node, 'Content-Type', null),
      device_record_type: safeTag(node, 'Device-Record-Type', null),
      device_id: safeTag(node, 'Device-Id', null),
      device_address: safeTag(node, 'Device-Address', null),
      device_manufacturer: safeTag(node, 'Device-Manufacturer', null),
      device_model: safeTag(node, 'Device-Model', null),
      device_merkel_root: safeTag(node, 'Device-Merkel-Root', null),
      device_registry: safeTag(node, 'Device-Registry', null),
      ifps_add: safeTag(node, 'IPFS-Add', null),
      device_token_metadata: safeTag(node, 'Device-Token-Metadata', null),
      device_minter: safeTag(node, 'Device-Minter', null),
      chain_id: safeTag(node, 'Device-Minter-Chain-Id', null),
    }
  })

  return device[0]
}
